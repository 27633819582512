var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:_vm.$style.seo},[_c('div',{class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.search},[_c('Autocomplete',{attrs:{"search":_vm.search,"placeholder":"Поиск","valueNames":['question']},on:{"querySearch":_vm.querySearch,"selectItem":_vm.handleSelectSeo,"handleFindItems":_vm.handleFindSeos,"input":function($event){_vm.search = $event}}}),_c('el-button',{class:_vm.$style.button,attrs:{"type":"primary"},on:{"click":_vm.getList}},[_vm._v(" Показать ")])],1),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.$router.push(
          _vm.getRoute({
            route: _vm.$options.ADDSELLERS_ADMIN_ROUTES.CONTENT.SEOFAQS.CREATE,
          }),
        )}}},[_vm._v(" Создать SEO-FAQ ")])],1),_c('el-table',{attrs:{"data":_vm.seoFAQs,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"question","label":"Вопрос"}}),_c('el-table-column',{attrs:{"prop":"answer","label":"Ответ","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{class:_vm.$style.answer,domProps:{"innerHTML":_vm._s(scope.row.answer)}})]}}])}),_c('el-table-column',{attrs:{"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('ActionButtons',{attrs:{"name":"trash","edit-link":_vm.getRoute({
              route: _vm.$options.ADDSELLERS_ADMIN_ROUTES.CONTENT.SEOFAQS.EDIT,
              params: { id: String(scope.row.id) },
            })},on:{"delete":function($event){return _vm.remove(scope.row)}}})]}}])})],1),_c('el-pagination',{class:_vm.$style.pagination,attrs:{"layout":"prev, pager, next","page-size":_vm.limit,"total":_vm.total,"current-page":_vm.page,"background":""},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event},"current-change":_vm.getList}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }